@import url('https://fonts.googleapis.com/css2?family=Oleo+Script&family=Rubik+Vinyl&display=swap');

@font-face {
  font-family: 'pancakeslim';
  src: local('pancakeslim'), url(./fonts/pancakeslims.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: pancakeslim, 'Oleo Script', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(176 155 155);
  background: radial-gradient(circle, rgb(255 255 255) 0%, rgb(238 237 237) 69%, rgb(162 162 162) 100%);
  touch-action: none;
  width: 100%;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

canvas {
  width: 100% !important;
  height: 100vh !important;
}

.htmlTopLayer {
  position: absolute;
  z-index: 1;
  width: auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  text-align: center;
}

h1 {
  font-family: pancakeslim, 'Oleo Script', cursive;
  color: white;
  font-size: 35px;
  width: 100%;
  margin: 5px auto;
  text-shadow: -1px 2px 2px black;
}

.logo {
  width: 250px;
  max-width: 100%;
  filter: drop-shadow(-1px 2px 2px black);
}

.linkedinLink {
  display: block;
  margin-top: 10px;
}

.linkedinLink img {
  width: 100px;
  transition: 0.4s all ease-out;
  filter: saturate(0) brightness(3) drop-shadow(-1px 2px 2px black);
}

.linkedinLink img:hover {
  filter: saturate(1) brightness(1);
}

.overlayWritings {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.overlayWritings > span {
  color: black;
  font-size: 90px;
  position: absolute;
  transition: 0.5s all;
  text-shadow: -1px 2px 2px #0000008c;
}

.overlayWritings > span:nth-of-type(1) {
  top: 7%;
  left: 31%;
}
.overlayWritings > span:nth-of-type(3) {
  top: 20%;
  left: 13%;
}
.overlayWritings > span:nth-of-type(4) {
  top: 40%;
  right: 20%;
}
.overlayWritings > span:nth-of-type(6) {
  bottom: 33%;
  left: 8%;
}
.overlayWritings > span:nth-of-type(5) {
  bottom: 23%;
  right: 16%;
}
.overlayWritings > span:nth-of-type(2) {
  bottom: 10%;
  left: 27%;
}

@media screen and (max-width: 500px) {
  .overlayWritings > span {
    font-size: 35px;
  }
}

@media screen and (min-width: 1800px) {
  .overlayWritings > span {
    font-size: 120px;
  }
}